import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";

import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

import locales from "../../../constants";

const Naturlig = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";
  return (
    <Layout
      seo={{
        title: "Naturlig Flex",
        href: slug,
        lang: "pl",
        ogImage: require("../../../assets/img/portfolio/naturlig_og.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #2884CA, #2895E8, #4BCFF7, #36B5EE, #2888D1 )",
        icons: "#fff",
        navClass: "piotrus_pan__nav",
        ogImage: require("../../../assets/img/portfolio/naturlig_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/naturlig/",
      }}
    >
      <PortfolioHeader name="naturlig" height="260" />
      <div className="naturlig">
        <section
          className="container-fluid portfolio-intro naturlig-section-2"
          id="info"
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="inner">
                <h1>NaturligFlex</h1>
                <ul>
                  <li>Logo</li>
                  <li>Packaging design</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner inner--divider">
                <p>
                  We have designed a packaging for a dietary supplement. The
                  project assumed the creation of a packaging with the use of
                  various techniques to refine printing and the product logo,
                  along with the brand book. In total, the design was adapted to
                  two different unit packages with blisters inside them and a
                  collective packaging.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid naturlig-section-3">
          <div className="inner">
            <h2>Logo</h2>
            <p>
              The graphic form of the logo was built using the product name
              itself on a modular grid. The blue color that dominates the sign
              has also become the main color used in further design works. This
              color was additionally supplemented with contrasting colors, which
              were used to create a gradient to highlight and emphasize the
              sign. The trademark book was created in two language versions for
              the proper use of the trademark.
            </p>
          </div>
          <div className="logo-section">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/naturlig_logo_colors.png")}
              alt=""
            />
            <div className="row">
              <div className="col-lg-5 col_1">
                <div className="img_wrapper text-center">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/img/portfolio/naturlig_logo.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col_2">
                <div className="row">
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>Blue CMYK</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 74 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 22 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>Violet CMYK</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 20 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 85 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>Orange CMYK</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 89 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 98 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>RGB:</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 39 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 157 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 217 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>RGB:</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 201 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 65 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 145 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>RGB:</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 230 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 54 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 22 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <div className="col-sm-4">
                    <p>HEX</p>
                    <h5>279dd8</h5>
                  </div>
                  <div className="col-sm-4">
                    <p>HEX</p>
                    <h5>c94191</h5>
                  </div>
                  <div className="col-sm-4">
                    <p>HEX</p>
                    <h5>e63616</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="naturlig-section-4">
          <div className="content">
            <img
              src={require("../../../assets/img/portfolio/naturlig_main_1.png")}
              alt=""
            />
            <div className="inner">
              <h2>Packaging</h2>
              <p>
                The project aimed to present the area of ​​health that the
                product supports and its properties in a clean but expressive
                form. The unit packaging was available in two sizes, in the
                production of which techniques such as embossing, UV varnish,
                and hot stamping were used.
              </p>
            </div>
            <img
              className="box"
              src={require("../../../assets/img/portfolio/naturlig_main_2.png")}
              alt=""
            />
          </div>
        </section>
      </div>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Naturlig;
